@import "../../scss/variables.scss";

.perfil-header{
	margin-top: 12px;
}

.title-meus-comentarios{
	border-top: 0 !important;
}

.perfil-item-comentario:last-child{
	border-bottom: 1px solid #f0f0f0;
}

.perfil-photo{
	.wrap-img{
		margin-right: 18px;
		vertical-align: middle;
	}

	svg{
		vertical-align: middle;
		color: $weak-green;
		cursor: pointer;
	}

	button{
		background-color: transparent;
		outline-color: transparent;
	}
}

.perfil-config{
	color: $weak-green;
	float: right;
	font-weight: 500 !important;

	@media(max-width: 992px){
		float: none;
		span{
			display: none;
		}
	}

	a { 
		display: block; 
		text-align: right;
		margin: 5px 0px;
	}


	svg{
		vertical-align: middle;
		margin-left: 8px;
	}
}

.perfil-nome{
	margin-top: 16px;
}

.respostas .comment {
	margin: 20px 0;
    padding: 10px;
    padding-top: 0;
    background-color: #FBFBFB;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
}