.input{
	background-color: transparent;
	display: block;
	width: 100%;
	border: 1px solid $smooth-gray;
	padding: 16px;
	border-radius: 4px;
	box-sizing: border-box;
	font-size: 14px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	outline-color: transparent;
	outline: transparent;

	&:focus{
		outline-color: transparent;
		outline: transparent;
	}

	&.disbled{
		background-color: transparent;
		border: 0;
		padding: 0;
	}

	&[type="submit"]{
		font-family: 'Roboto Slab', serif;
		font-weight: 700;
	}

	&.login-with{
		font-family: 'Roboto Slab', serif;
		font-weight: 700;
		font-weight: bold;

		@media(max-width: 992px){
			font-size: 10px;
		}
	}

	&.textarea{
		height: 128px;
		resize: none;
		outline-color: transparent;
		outline: transparent;

		&:focus{
			outline-color: transparent;
			outline: transparent;
		}
	}

}

.input-search{
	background-color: $light-gray;
	border-radius: 100px;
	padding: 8px 16px;
	border: 1px solid #F0F0F0;
	color: #B0B0B0;
	position: relative;
	z-index: 1;
	font-size: 16px;
	font-weight: 300;
	font-family: 'Rubik', sans-serif;

	i{
		position: absolute;

		&.icon-left{
			top: 8px;
			left: 10px;
		}

		&.icon-right{
			top: 8px;
			right: 8px;
		}
	}

	input{
		background-color: transparent;
		border: 0;
		width: 100%;
		padding: 5px 0;

		&.has-icon-left{
			padding-left: 30px;
		}

	}
}

.input-margin{
	.input{
		margin-bottom: 20px;
	}
}

.submit { cursor: pointer; }

.btn{
	padding: 17px 24px;
	border-radius: 100px;
	display: inline-block;
	outline-color: transparent;
	outline: transparent;

	&:focus{
		outline-color: transparent;
		outline: transparent;
	}
}

.btn-outline{
	background-color: transparent;
	border: 1px solid $smooth-gray;
}

.error{
	padding: 16px;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    background-color: #cc3434;
    color: white;
    display: block;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
}

.item-form{
	position: relative;

	.message{
		position: absolute;
		color: $red-google;
		top: 53px;
		left: 0;
		font-size: 10px;
		font-family: 'Roboto', sans-serif;
		display: none;
	}

	&.invalid{
		input{
			border-color: $red-google;
		}

		.message{
			display: block;
		}
	}
}

/***************** Area Admin ********************/
.textarea-multiline textarea{
	min-height: 50px;
}

.simple-form .MuiGrid-container{
	width: 100% !important;
}