.page-aguardando-confirmacao{
	padding-bottom: 60px;

	.celular-title{
		margin-bottom: 16px;
		font-size: 20px;
		font-weight: 500;
		font-family: 'Roboto', sans-serif;
	}

	.celular-number{
		margin: 32px 0;
		font-weight: 500;
		font-family: 'Roboto', sans-serif;
	}

	.submit{
		margin: 16px 0 32px;
	}

	.celular-after{
		margin-bottom: 32px;
		display: inline-block;
	}
}
