$white: #ffffff;
$orange: #cf5f22;
$orange-shade: rgba(207, 95, 34, 0.2);
$weak-green: #5F7980;
$red-google: #D04333;
$blue-facebook: #3C66C5;

$smooth-gray: #f0f0f0;
$light-gray: #FBFBFB;
$bright-gray: #707070;
$clear-gray: #B0B0B0;
$dark-gray: #303030;