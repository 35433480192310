@import "../../scss/variables.scss";

.page-criar-conta{
	padding-bottom: 100px;

	.link-criar-conta{
		margin-top: 64px;
	}

	.note-info{
		position: absolute;
		background-color: $dark-gray;
		border-radius: 5px;
		width: 306px;
		color: white;
		padding: 10px;
		right: -13px;
		font-size: 13px;
		font-family: 'Roboto', sans-serif;
		font-weight: bold;
		font-style: normal;
		z-index: 1;
		top: 31px;
		display: none;

		&:before {
			content: "";
			width: 0;
			height: 0;
			border: 10px solid transparent;
			border-bottom-color: $dark-gray;
			position: absolute;
			right: 15px;
			top: -16px;
		}
	}

	.icon-info{
		position: absolute;
		right: 13px;
		top: 13px;
		color: $clear-gray;
		cursor: pointer;

		&:hover .note-info{
			display: block;
		}
	}

	.forca-senha{
		font-family: 'Roboto', sans-serif;
	    margin-bottom: 10px;
    	display: block;
	}

	.terms-accept{
		margin-bottom: 20px;

		span{
			margin-left: 5px;
		}

		.message{
			top: 20px;
		}

input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom checkbox appearance */
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 4px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #5F7980;
    border-radius: 6px;
    vertical-align: middle;

    &:checked{
        background-color: #5F7980;
    }

    &:focus{
        outline: none !important;
    }
}
	}
}

.squaredThree {
  width: 20px;
  position: relative;
  margin: 20px auto;
  label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, #222 0%, #45484d 100%);
    border-radius: 4px;
    box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,.4);
    &:after {
      content: '';
      width: 9px;
      height: 5px;
      position: absolute;
      top: 4px;
      left: 4px;
      border: 3px solid #fcfff4;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
    &:hover::after {
      opacity: 0.3;
    }
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }    
  }
}