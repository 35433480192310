@import "../../scss/variables.scss";

.page-configuracoes-perfil{

	.configuracoes-perfil-grid{
		@media(max-width: 992px){
			width: 100%;
		}
	}

	.configuracoes-perfil-photo{
		clear: both;
		overflow: auto;
		vertical-align: middle;
		margin: 32px 0;

		.link-green{
			line-height: 80px;
			margin-left: 20px;
			display: inline-block;
			background-color: transparent;
			border: 0;
			outline-color: transparent;
		}

		.wrap-img{
			float: left;
			vertical-align: middle;
		}
	}

	.title-main{
		margin-bottom: 32px;
	}

	.title-sub{
		padding-top: 32px;
	}

	.configuracoes-perfil-sessao{
		margin-bottom: 8px;
	}

	.btn{
		margin: 23px 0 33px;
		font-weight: 900;
	}

	.padding{
		padding: 32px 0;
	}

	button{
		background-color: transparent;

		@media(max-width: 992px){
			margin-left: 15px;
		}
	}

	.message{
		color: #D04333;
		font-size: 10px;
		font-family: 'Roboto', sans-serif;
		display: none;
		top: 212px !important;

		&.invalid{
			display: block;
		}
	}

	.icon-info{
		position: absolute;
		right: 68px;
		top: 28px;
		color: $clear-gray;
		cursor: pointer;

		&:hover .note-info{
			display: block;
		}

		@media(max-width: 992px){
			right: 0;
		}
	}

	.note-info{
		position: absolute;
		background-color: $dark-gray;
		border-radius: 5px;
		width: 306px;
		color: white;
		padding: 10px;
		right: -13px;
		font-size: 13px;
		font-family: 'Roboto', sans-serif;
		font-weight: bold;
		font-style: normal;
		z-index: 1;
		top: 31px;
		display: none;

		&:before {
			content: "";
			width: 0;
			height: 0;
			border: 10px solid transparent;
			border-bottom-color: $dark-gray;
			position: absolute;
			right: 15px;
			top: -16px;
		}
	}

	.alterar-senha-valid {
		display: none;
	}
	.alterar-senha-invalid {
		position: relative;
		.message {
			position: initial;
			display: block;
			margin: 5px;
		}
	}

	.forca-senha {
	    font-family: 'Roboto', sans-serif;
	    margin-bottom: 10px;
	    display: block;
	}

	
}
