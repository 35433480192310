  @import "../scss/variables.scss";

  .side-menu {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 92px;
    padding-top: 64px;
    background-color: $white;
    box-shadow: inset -1px 0px 0px $smooth-gray;
    z-index: 10;
    text-align: center;

    .side-logo{
      margin-bottom: 35px;
      display: inline-block;

      @media(max-width: 992px){ 
        margin-bottom: initial;
      }
    }

    ul{
      list-style-type: none;
      padding: 0px;
    }

    a{
      color: $weak-green;
      margin-bottom: 25px;
      display: inline-block;
      padding: 0 28px;
    }

    .login-wrapper{
      position: relative;

      .login-hover{
        position: absolute;
        top: 7px;
        left: 40px;
        width: 340px;
        z-index: 1;
        &.hidden {
          display: none;
        }

        &.show{
          display: block;
        }

        @media(max-width: 992px){
          display: none !important;
        }

        .login-box{
          width: 350px;

          .login-with{
            width: 170px;
            height: 50px;
            border-radius: 5px;

            .icon{
              left: 50%;
              transform: translate(-50%,-50%);
            }

            &.google{
              background-color: $red-google;
              margin-right: 6px;

              .icon-google{
                background-image: url('../assets/icons/google-white.svg');
              }
            }

            &.facebook{
              background-color: $blue-facebook;
              margin-left: 6px;
              border: 0;

              .icon-facebook{
                background-image: url('../assets/icons/facebook-white.svg');
              }
            }
          }
        }
      }
    }

    @media(min-width: 993px){
      .item-bottom{
        position: fixed;
        bottom: 0;
        left: 0;
        a { margin-bottom: 18px; }
      }
    }

    @media(max-width: 992px){
      height: 77px;
      box-sizing: border-box;
      width: 100%;
      right: 0;
      border-bottom: 1px solid $smooth-gray;
      padding: 20px 24px;
      display: flex;
      align-items: center;

      img{
        margin-bottom: 0;
      }

      a{
        margin-bottom: 0;
      }

      ul{
        display: inline;
        float: right;
        display: flex;
        align-items: center;

        li{
          display: inline;
          float: left;

          &:first-child{
            display: none;
          }

          a{
            margin: 0 10px;
          }
        }   
      }

    }

    @media(max-width: 495px){
      ul{
        li{
          a{
            margin-right: 0;
            padding: 0 13px;
          }
        }   
      }
    }
  }