*{
	margin: 0;
	padding: 0;

	&:focus{
		outline-color: transparent;
	}
}

html, body{
	height: 100%;
}

body{
    @media(max-width: 992px){
        padding-top: 77px;
    }
}

a, button{
	cursor: pointer;
}

a, a:focus, a:hover{
	text-decoration: none;
	color: inherit;
}

input{
	outline-color: transparent;
}

body{
	background-color: $white;
	
	@media(min-width: 991px){
		padding-left: 92px;
	}
}

input:focus, a:focus, i:focus, div:focus { outline: 0; -webkit-box-shadow: none !important; -moz-box-shadow: none !important; box-shadow: none !important; }