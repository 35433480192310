button {
	border: none;
}

.title{
	font-size: 32px;
	font-family: 'Roboto Slab', serif;
	font-weight: 600;

	@media(max-width: 992px){
		font-size: 27px;
	}
}

.title-medium{
	font-size: 20px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
}

.title-normal{
	font-size: 16px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
}

.subtitle-danger {
	font-size: 12px;
    color: red;
}

.title-small{
	font-size: 12px;
	font-family: 'Roboto Slab', serif;
	font-weight: 700;
}

.title-slab{
	font-size: 20px;
	font-family: 'Roboto Slab', serif;
	font-weight: 600;

	&.margin-32{
		margin-bottom: 32px;
	}
}

.text-slab{
	font-size: 16px;
	font-family: 'Roboto Slab', serif;
	font-weight: 700;
}

.text-slab-medium{
	font-size: 14px;
	font-family: 'Roboto Slab', serif;
	font-weight: 700;
}

.text-normal{
	font-size: 16px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
}

.text-default{
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
}

.text-small{
	font-size: 14px;
}

.box-light-gray{
	background-color: $light-gray;
	border-radius: 4px;
}

.d-inline-block{
	display: inline-block;
}

.selected-search-result .highlight-search{
	background: rgba(237,208,64,0.5);
}

.link-green{
	display: block;
	color: $weak-green;
	text-decoration: underline;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;

	&:hover{
		color: $weak-green;
	}
}

.legislacao-loading{
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -25px;
	margin-left: -25px;
	
	.MuiCircularProgress-svg{
		color: $weak-green;
	}
}

.wrap-img-80{
	width: 80px;
	height: 80px;
}

.wrap-img-36{
	width: 36px;
	height: 36px;
}

.wrap-img{
	display: inline-block;
	border-radius: 50%;
	background-color: $smooth-gray;
	background-size: cover;
}

.box-border{
	padding: 20px;
	border: 1px solid $smooth-gray;
}

.border-top{
	border-top: 1px solid $smooth-gray;
}

.no-border-bottom{
	border-bottom: 0;
}

.scroll{

	&::-webkit-scrollbar {
	    width: 7px;
	}

	&::-webkit-scrollbar-track {
	    background: $smooth-gray;
	    border-radius: 0px;
	}

	&::-webkit-scrollbar-thumb {
	    background: $clear-gray;
	    border-radius: 70px;
	}

}

.comment-title-tag{
	font-size: 10px;
	font-style: italic;
	display: inline-block;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	
	&.space{
		margin-left: 8px;
	}
}

.comment-title{
	font-size: 16px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	word-break: break-all;
}

.comment-header{
	margin: 16px 0;

	.wrap-img{
		vertical-align: middle;
		margin-right: 13px;
		background-size: cover;
    	background-position: center center;
	}
}

.comment-more{
	color: $weak-green;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 700;
	margin-left: 5px;
	background-color: transparent;
	border: 0;
	outline-color: transparent;
}

.comment-footer{
	margin-top: 33px;
	vertical-align: middle;

	svg{
		vertical-align: middle;
	}
}

.comment-likes{
	color: $weak-green;
	vertical-align: middle;
	display: inline-block;
	margin-right: 23px;

	&.liked {
		color: $orange;
	}

	.number{
		margin-left: 6px;
		vertical-align: middle;
		font-size: 10px;
		font-family: 'Roboto', sans-serif;
		font-weight: 900;
	}
	svg {
		cursor: pointer;
	}
}

.comment-time{
	font-size: 10px;
	color: $clear-gray;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;

	&.space{
		margin-right: 23px;
	}
}

.comment{
	color: $dark-gray;
}

.comment-content {
	a { 
		text-decoration: underline;
	}
	ol, ul {
		padding-left: 20px;
	}
	p, ol, ul {
		padding-top: 5px;
	}
}

.comment-editor{
	border: 1px solid $smooth-gray;
	margin-top: 35px;
	position: relative;

	@media(max-height: 745px){
		margin-top: 15px;
		/*height: 200px;
		overflow-y: scroll;*/
	}

	.comment-header{
		padding: 0 20px;

		@media(max-height: 745px){
			margin: 10px 16px;
			padding: 0;
		}
	}

	.close{
		position: absolute;
		top: 13px;
		right: 13px;
		cursor: pointer;
	}

	.comment-editor-header{
		padding: 20px;
		border-top: 1px solid $smooth-gray;
		border-bottom: 1px solid $smooth-gray;

		@media(max-height: 745px){
			padding: 8px 16px;
		}

		button{
			background-color: transparent;
			margin-right: 14px;
			outline-color: transparent;
			border: 2px solid transparent; 

			&.active { 
				border: 2px solid $orange; 
				border-radius: 2px;
			}

			svg{
				color: $weak-green;
			}
		}
	}

	.comment-link{
		padding: 20px;
		border-bottom: 1px solid $smooth-gray;
		position: relative;

		button{
			position: absolute;
			right: 20px;
			top: 21px;
			border-radius: 0;
		}
	}

	.comment-editor-textarea{
		padding: 20px;
		font-weight: 300;
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		min-height: 120px;
		height: 120px;
		overflow: hidden;
		overflow-y: scroll;

		@media(max-height: 745px){
			padding: 15px 20px;
			min-height: 80px;
			height: 80px;
		}

		a{
			color: $orange !important;
		}

		div > div[data-contents="true"]{
			padding-bottom: 25px;
		}
	}

	.caracteres-restantes{
		@media(max-height: 745px){
			padding: 10px 20px !important;
		}
	}

	.comment-editor-submit{
		padding: 16px 20px;
		font-weight: bold;
		text-transform: uppercase;

		@media(max-height: 745px){
			padding: 10px 20px;
		}
	}
}

.comment-detalhes{
	position: relative;

    @media(max-width: 992px){
        position: unset;
    }

	.comment-opcoes{
		position: absolute;
		right: 0;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		background-color: white;
		padding: 32px 20px;
		width: 260px;
		top: -150px;
		right: 30px;
		z-index: 1;

	    @media(max-width: 992px){
	    	position: fixed;
	        top: unset;
	        right: 0;
	        left: 6px;
	        bottom: 66px;
	    }

		.title-normal{
			margin-bottom: 17px;
		}

		button {
			margin-bottom: 17px;
			display: block;
			border: none;
			background-color: transparent;

			svg{
				color: $weak-green;
				margin-right: 21px;
			}
		}
	}
}

.modal-box{
	position: absolute;
	width: 440px;
	border-radius: 4px;
	background-color: white;
	box-shadow: '0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1)';
	padding: 32px 20px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	&.modal-small{
		width: 380px;
	}

	&.modal-mobile-small{
		
		@media(max-width: 540px){
			width: 200px;
		}
	}
}


.title-modal{
	margin-bottom: 32px;
}

.btn-modal{
	margin-top: 32px;
	text-transform: uppercase;
	font-weight: bold;
}

.btn-disabled{
	color: #c4c2c2 !important;
	background-color: $smooth-gray !important;
	pointer-events: none !important;
}

.close-modal{
	position: absolute;
	top: 13px;
	right: 13px;
	cursor: pointer;
}

.modal-container{
	& > div:first-child{
		background: rgba(13, 98, 115, 0.5) !important;
	}
}

.react-tabs__tab-list{
	font-size: 14px;
	font-family: 'Roboto Slab', serif;
	font-weight: 700;
	margin: 50px 0 10px;
}

.react-tabs__tab--selected{
	background: transparent;
	border: 0;
	border-bottom: 2px solid $orange;
	color: $orange;
}

.btn-snackbar span{
	color: white;
	margin-right: 5px;
	text-transform: none;
}

.pre-line{
	white-space: pre-line;
}