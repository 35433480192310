@import "../../scss/variables.scss";

.page-legislacao {
    // position: absolute;
    // bottom: 0;
    // top: 0;
    // right: 0;
    // left: 92px;
    height: 100vh;

    .container-legislacao {
        height: 100%;
        position: relative;

        @media(max-width: 992px){
            &.comentario-expandido{
                .footer-legislacao{
                    display: block;
                }

                div.rodape{
                    bottom: 40% !important;
                }   
            }
        }

        .left-panel {
            background-color: $light-gray;
            box-shadow: inset -1px 0px 0px $smooth-gray;
            position: relative;
            height: 100vh;

            @media(max-width: 992px){
                height: 100%;
                max-width: 100% !important;
                //bottom: 78px;
                //top: 77px;

                &.comentario-expandido{
                    height: 45%;
                    bottom: unset;
                    position: absolute;

                    .card-container{
                        bottom: 0px !important;
                    }

                    .card-view.card-container{
                        height: calc(40vh - 40px);
                    }
                }   
            }

            .highlight-search {
                color: $orange;
            }

            .legislacao-header {
                background-color: white;
                margin: 0px;
                padding: 10px 0px;
                box-shadow: inset 0px -1px 0px $smooth-gray, inset -1px 0px 0px $smooth-gray;
                position: relative;

                .busca-nav-desc{
                    width: 130px;
                    vertical-align: middle;
                    font-family: 'Roboto', sans-serif;
                }

                .legislacao-nav{
                    position: absolute;
                    top: 100%;
                    right: 0;
                    background-color: white;
                    padding: 3px 8px 0px;
                    border: 1px solid $smooth-gray;
                    border-radius: 3px;
                    z-index: 1;
                    color: $clear-gray;
                    font-size: 12px;

                    span{
                        padding: 2px;
                        display: inline-block;
                        cursor: pointer;
                        vertical-align: middle;
                    }
                }

                .icon-left {
                    margin-left: 24px;
                }

                .legislacao-info {
                    position: relative;
                    margin-left: 23px;

                    h3 {
                        font-family: "Roboto Slab", serif;
                        font-weight: 300;
                        font-size: 12px;
                    }
                }

                .icon-right {
                    margin-right: 24px;
                }

                input {
                    width: 100%;
                    position: absolute;
                    top: 0px;
                    left: -5px;
                    height: 38px;
                    border: 0;
                    font-family: "Roboto", sans-serif;

                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: 100% 100%;
                    transform-origin: 100% 100%;
                    opacity: 1;
                    
                    @media(max-width: 1301px){
                        left: -10px;
                    }

                    &.open {
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                        -webkit-transform-origin: 100% 100%;
                        transform-origin: 100% 100%;
                        opacity: 1;

                        -webkit-animation: scale-in-hor-right 0.5s ease-in normal;
                        animation: scale-in-hor-right 0.5s ease-in normal;
                    }
                    &.closed {
                        -webkit-animation: scale-in-hor-right-reverse 0.5s ease-in;
                        animation: scale-in-hor-right-reverse 0.5s ease-in;
                    }
                }

                @media(max-width: 992px){
                    top: 77px;
                    position: fixed;
                    z-index: 10;
                }
            }

            .card-view-nivel{
                border-bottom: 1px solid $smooth-gray;

                &.next-scrolled{
                    border-top: 1px solid $smooth-gray;
                       
                    &.nivel-40, &.nivel-50{
                        z-index: 2;
                        position: relative;
                    }
                }

                &.scrolled{
                    border-top: 0;
                    border-bottom: 0;
                }

                &.nivel-20 p, &.nivel-75 p {
                    margin-right: 5px;
                    color: #5F7980;
                    font-size: 16px;
                    font-family: 'Roboto Slab', serif;
                    font-weight: 700;

                    span {
                        display: none;
                    }
                }
            }

            .card-fixed-header{
                position: absolute;
                top: 57px;
                left: 0;
                right: 0;
                padding: 15px 10px;
                background-color: white;
                font-size: 16px;
                z-index: 1;
                display: none; 
                font-family: "Roboto Slab", serif;
                border-bottom: 1px solid $smooth-gray;

                .text-slab {
                    margin-right: 5px;
                }
            }

            .card-fixed-header.active{
                display: block;
            }

            div.left-panel-content {
                flex: 1;
            }

            div.rodape {
                background-color: white;
                margin: 0px;
                padding: 17px 0px 18px;
                box-shadow: inset 0px 1px 0px #F0F0F0, inset -1px 0px 0px #F0F0F0;
                z-index: 10;
                position: absolute;
                bottom: 0;
                width: 100%;
                z-index: 11;

                @media(max-width: 992px){
                    padding: 10px;
                    z-index: 10;
                    position: fixed;
                    bottom: 0;
                }

                i {
                    background-color: $light-gray;
                    color: $weak-green;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    border-radius: 50%;
                    cursor: pointer;
                    &.icon-right {
                        margin-right: 12px;
                    }
                    &.active {
                        background-color: $orange-shade;
                        color: $orange;
                    }

                    svg {
                        padding: 3px 0;
                    }
                }
            }
        }

        .card-container {
            position: absolute;
            top: 57px;
            left: 0;
            bottom: 67px;
            right: 0;
            overflow: hidden;

            &.overflow-y{
                overflow-y: scroll; //Pra dar scroll do Sumário                
            }

            .ReactVirtualized__Grid{
                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    background: $smooth-gray;
                    border-radius: 0px;
                }

                &::-webkit-scrollbar-thumb {
                    background: $clear-gray;
                    border-radius: 70px;
                }
            }

            @media(max-width: 992px){
                top: 134px;
                position: fixed;
            }
        }

        div.card-view {

            &.card-summary {
                h1, h2 { display: inline; }
                .nivel-20 > h2 { display: none; }
                .nivel-20 > h1 { padding-left: 10px; }
                .nivel-30 > h1 { padding-left: 20px; }
                .nivel-40 > h1 { padding-left: 30px; }
                .nivel-50 > h1 { padding-left: 40px; }
                .nivel-60 > h1 { padding-left: 50px; }
            }

            div.MuiBox-root {
                padding: 15px 10px;
                background-color: white;
                font-size: 16px;
                cursor: pointer;

                .text-slab {
                    margin-right: 5px;
                }

                p {
                    display: inline-block;
                    text-align: justify;
                    font-size: 16px;
                    font-family: "Roboto Slab", serif;
                }

                div.comment-container {
                    margin-top: 10px;
                    display: flex;

                    svg {
                        width: 18px;
                        height: 18px;
                        color: $clear-gray;
                    }

                    span.comments {
                        margin-left: 5px;
                        font-size: 12px;
                        color: $clear-gray;
                        display: flex;
                        align-items: center;
                        font-family: "Roboto", sans-serif;
                    }
                }

                &.selected {
                    box-shadow: inset 0px -1px 0px $smooth-gray, inset -1px 0px 0px $smooth-gray,
                        inset 4px 0px 0px $orange;
                }

                &.expired {
                    p {
                        text-decoration: line-through;
                    }
                }
            }
        }

        .right-panel {
            /*position: relative;*/
            background-color: $light-gray;

            @media(min-width: 993px){
                height: 100vh;
            }

            .comentarios-container {
                /*position: relative;
                height: 100%;
                overflow: hidden;*/
                height: 100vw;
                overflow-y: scroll;

                .header-breadcrumb {
                    display: flex;
                    height: 57px;
                    box-shadow: inset 0px -1px 0px $smooth-gray, inset -1px 0px 0px $smooth-gray;
                    color: $clear-gray;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    align-items: center;
                    padding: 0px 20px;
                    font-family: "Roboto Slab", serif;
                    background-color: #fff;
                    position: absolute;
                    width: 100%;
                    z-index: 10;
                    top: 0;

                    @media(max-width: 992px){
                        height: auto;
                        padding-bottom: 15px;
                        top: -1px;
                        padding-top: 10px;
                        border-top: 1px solid $smooth-gray;
                    }
                }
                .legislacao {
                    padding: 32px 20px;
                    font-size: 20px;
                    border-bottom: 1px solid $smooth-gray;
                    background-color: #ffffff;
                    /*margin-top: 57px;*/

                    .expired {
                        text-decoration: line-through;
                    }

                    p {
                        display: inline;
                        padding-left: 5px;
                        font-family: "Roboto", sans-serif;
                        font-weight: 300;
                    }

                    &.nivel-20 p, &.nivel-75 p {
                        display: none;
                    }

                    span {
                        font-family: "Roboto Slab", serif;
                    }

                    .btn-outline{
                        text-transform: uppercase;
                        color: $weak-green;
                        font-weight: 900;
                        display: block;
                        margin: 32px 0 0 0; 
                        outline-color: transparent;
                    }
                }

                .comentarios {
                    /*position: absolute;
                    top: 254px;
                    left: 0;
                    bottom: 65px;
                    right: 0;
                    overflow: hidden;
                    overflow-y: scroll;

                    @media(min-width: 992px){
                        top: 231px;
                    }*/
                }

                .comment {
                    padding: 32px 20px;
                    background-color: #ffffff;
                }

                &.comment-open{
                    .comentarios {
                        top: 647px;
                    }
                }

                .comentario-item-container {
                    border-bottom: 1px solid $smooth-gray;
                    background-color: #ffffff;
                    .respostas {
                        .comment {
                            margin: 20px;
                            background-color: $light-gray;
                            border: 1px solid $smooth-gray;
                            border-radius: 4px;
                        }
                    }

                    &:last-item{
                        margin-bottom: 65px;
                    }
                }
            }
        }
    }

    .resultados-query{

        .header-resultados-query{
            border-bottom: 1px solid $smooth-gray;
            width: 100%;
            height: 79px;
            background-color: white;
            position: relative;

            @media(max-width: 992px){
                top: 89px;
            }

            .back{
                position: absolute;
                top: 50%;
                margin-top: -12px;
                left: 20px;
                color: $weak-green;
            }

            .search-icon{
                position: absolute;
                top: 50%;
                margin-top: -12px;
                left: 73px;
                color: $clear-gray;
                z-index: 1;

                svg{
                   width: 20px; 
                }
            }

            .input-search{
                position: absolute;
                top: 50%;
                margin-top: -20px;
                right: 20px;
                left: 64px;
                background-color: white;
                padding-left: 35px;

                input{
                    width: 100%;
                }
            }
        }

        .list-resultados-query{
            overflow-y: scroll;
            position: absolute;
            top: 80px;
            bottom: 65px;
            left: 0;
            right: 0;
            overflow-y: scroll;

            @media(max-width: 992px){
                top: 169px;
            }

            .item-resultados-query{
                background-color: white;
                padding: 32px 53px 32px 20px;
                position: relative;
                cursor: pointer;
                border-left: 4px solid transparent;
                border-bottom: 1px solid $smooth-gray;
                border-right: 1px solid $smooth-gray;

                h3{
                    color: $weak-green;
                }

                p{
                    margin-top: 8px;
                }

                span{
                    position: absolute;
                    top: 50%;
                    margin-top: -14px;
                    right: 24px;
                    color: $weak-green;
                }

                &.active{
                    border-left-color: $orange;

                    h3{
                        color: $orange;
                    }

                    span{
                        color: $orange;
                    }
                }
            }
        }

        .footer-resultados-query{
            height: 30px;
        }

        .footer-legislacao-resultados-query{
            height: 45px;
        }
    }

    .text-view {
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        color: $dark-gray;

        div {
            .ReactVirtualized__List{
                padding: 20px;
            }
        }

        .nivel-20, .nivel-30, .nivel-40, .nivel-50 , .nivel-60 {
            text-align: center;
            .titulo {
                display: block;
                text-align: center;
            }
            p {
                margin: 15px 0px;
            }
        }

        .nivel-20 {
            .titulo {
                display: none;
            }
            color: $weak-green;
            font-size: 22px;
            font-weight: 500;
        }

        .nivel-30 {
            font-size: 14px;

            .titulo {
                color: $weak-green;
                font-weight: 500;
            }
        }

        .nivel-40,
        .nivel-50 {
            font-size: 14px;

            .titulo {
                color: $weak-green;
                font-weight: 500;
                margin-right: 3px;
            }
        }
        .nivel-75 {
            .titulo {
                display: none;
            }
            font-size: 14px;
            font-weight: 400;
        }
        p {
            display: inline-block;
            text-align: justify;
            margin-bottom: 10px;
        }
    }
    .container-legislacao-list {
        h1 {
            margin-top: 128px;

            @media(max-width: 992px){
                margin-top: 50px;
            }
        }

        .legislacao-list {
            margin-top: 32px;
            display: flex;
            justify-content: flex-start;
            .legislacao-item {
                flex-basis: 33%;
                background-color: white;
                border: 1px solid $smooth-gray;
                padding: 32px 20px;
                box-sizing: border-box;
                border-radius: 4px;
                height: 143px;
                margin-bottom: 7px;
                margin-right: 7px;
                overflow: hidden;

                @media(max-width: 992px){
                    flex-basis: 100%;
                }

                a {
                    font-weight: 600;
                    font-size: 20px;
                    span { 
                        display: block; 
                        margin-top: 8px;
                    }
                }
            }
        }
        
    }
}

.footer-legislacao{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: right;
    padding: 10px;
    background-color: $white;
    box-shadow: inset 0px 1px 0px #F0F0F0, inset -1px 0px 0px #F0F0F0;

    @media(max-width: 992px){
        //bottom: 28px;
        position: fixed;
        bottom: 0px;
        padding: 0;
        display: none;
    }

    .btn-legislacao{
        background-color: $light-gray;
        border: 0;
        margin-left: 20px;
        padding: 9px 11px;
        border-radius: 50px;

        svg{
            color: $weak-green;
        }

        &.active{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #CF5F22;

            svg{
                color: $orange;
            }
        }

        &.p-relative{
            @media(max-width: 992px){
                position: unset;
            }
        }
    }
    .menu-comment-opcoes {
        position: absolute;
        bottom: 100%;
        right: 0;
        padding: 20px;
        border: 1px solid #edebeb;
        background: #ffffff;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        width: 300px;
        height: 172px;
        text-align: left;

        @media(max-width: 992px){
            width: auto;
            left: 0;
        }

        h4 {
            margin: 16px 0px;
        }
        hr {
            margin: 32px 0px;
        }
        button {
            display: flex;
            align-items: center;
            margin: 16px 0px;
            justify-content: space-between;
            width: 100%;
            height: 20px;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.pesquisas-recentes{
    position: absolute;
    top: 57px;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 15px;
    background-color: white;
    bottom: 0;
    border-right: 1px solid $smooth-gray;

    @media(max-width: 992px){
        top: 78px;
    }

    h3.title-normal{
        margin-bottom: 30px;

        svg{
            width: 17px;
            margin-top: -4px;
            cursor: pointer;
        }
    }

    .item-pesquisa{
        margin-bottom: 20px;
        color: $dark-gray;

        svg{
            color: $smooth-gray;
            width: 22px;
            margin-right: 17px;
            vertical-align: middle;
        }
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-6 17:26:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-hor-right {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
}
@keyframes scale-in-hor-right {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
}

@-webkit-keyframes scale-in-hor-right-reverse {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
}
@keyframes scale-in-hor-right-reverse {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
}


.container-legislacao.item-selected{
   .right-panel{
        @media(max-width: 992px){
            bottom: 44px;
            position: fixed;
            top: 60%;
            border: 1px solid #edebeb;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
            background: white;
            max-width: 100% !important;
        }

        .comentarios-container{
            margin-top: 57px;

            @media(min-width: 993px){
                margin-bottom: 66px;
            } 

            @media(max-width: 992px){
                padding-top: 10px;
                overflow-y: scroll;
            } 

            .comentarios{
                @media(max-width: 992px){
                    position: unset;
                    min-height: 64px;
                } 
            }
        }
    }
}