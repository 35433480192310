@import "../scss/variables.scss";

.modal-exibir-perfil{
	
	.title-modal-wrapper{
		margin-left: 16px;
		position: absolute;
    	top: 50%;
    	transform: translateY(-50%);

		.title-modal{
			margin-bottom: 0;
			margin-top: -7px;
		}

		.mini-curriculo{
			font-size: 12px;
		}
	}

	.mini-curriculo-scroll{
		height: 50px;
		overflow-y: scroll;

		&::-webkit-scrollbar-track {
			background: white;
		}
	}
}

.box-at{
	list-style-type: none;
	position: absolute;
	top: 70px;
	padding: 16px;
	left: 20px;
	right: 20px;
	background-color: white;
	z-index: 2;
	border: 1px solid $clear-gray;
	color: $clear-gray;
	height: 60px;
    overflow-y: scroll;

	li{
		margin-bottom: 5px;
		span {
			margin-left: 10px;
			position: absolute;
			line-height: 36px;
		}
		&:hover{
			cursor: pointer;
			color: $orange;
		}
	}
}

.caracteres-restantes{
	border-bottom: 1px solid #f0f0f0;
	border-top: 1px solid #f0f0f0;
	padding: 20px !important;
	display: block !important;
}