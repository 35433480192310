@import "../../scss/variables.scss";

.page-login{
	padding-bottom: 100px;
}

.login-logo{
	margin: 70px auto;
	display: block;

	@media(max-width: 992px){
		margin: 30px auto;
	}
}

.login-box{
	background-color: $white;
	padding: 20px;
	border: 1px solid $smooth-gray;
	border-radius: 4px;

	@media(min-width: 991px){
		width: 480px;
		margin: 0 auto;
	}
}

.login-title{
	font-size: 20px;
	margin-bottom: 32px;
	font-family: 'Roboto Slab', serif;
	font-weight: 500;
}

.login-with{
	text-transform: uppercase;
	font-size: 14px;
	position: relative;

	.icon{
		position: absolute;
		left: 14px;
		top: 50%;
		transform: translateY(-50%);
	}

	&.google{
		color: $red-google;
	}

	&.facebook{
		color: $blue-facebook;
	}
}

.login-separator{
	display: block;
	text-align: center;
	margin-bottom: 16px;
	color: $bright-gray;
	font-size: 14px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
}

.link-after{
	margin-top: 32px;
	margin-bottom: 64px;
}

.btn-submit {
	cursor: pointer;
}