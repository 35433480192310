@import "../../scss/variables.scss";

.page-home {
	padding-top: 120px;

    @media(max-width: 992px){
        padding-top: 40px;
    }

    .logo{
    	width: 120px;
    }

	.title-home{
		margin: 37px 0 64px;
	}

	.home-sobre{
		padding: 100px 0;

		@media(max-width: 992px){
			padding: 50px 0;
			margin-top: 60px;
		}

		.title{
			margin-bottom: 32px;
		}
	}

	.input-search{
		padding-right: 72px;
		position: relative;

		.recent-search{
			position: absolute;
			left: -1px;
			right: -1px;
			padding: 8px 16px;
   			border-bottom: 1px solid #F0F0F0;
   			border-left: 1px solid #F0F0F0;
   			border-right: 1px solid #F0F0F0;
   			border-radius: 0 0 20px 20px;
   			font-size: 12px;
   			min-height: 100px;
   			display: none;

   			input{
   				display: block;
   				padding: 5px 0 5px 30px;
   				cursor: pointer;
   				color: #707070;
   			}
		}

		&.open{
			border-radius: 20px 20px 0 0;

			.recent-search{
				display: block;
			}
		}
	}

	.legislacao-loading {
		position: relative;
    	top: 50px;
	}

	.footer{
		padding: 64px 0;
		color: $clear-gray;
		font-size: 14px;
		font-family: 'Roboto Slab', serif;
		font-weight: 300;

		.footer-img{
			margin-bottom: 16px;
		}
	}
}

.ultimas-leis{

	.box-light-gray{
		padding: 32px 84px 32px 20px;
		float: right;

		@media(max-width: 959px){
			float: none;
			width: 100%;
			box-sizing: border-box;
		}

		.title-small{
			margin-bottom: 16px;
		}

		a{
			display: inline-block;
			margin-bottom: 10px;
			font-family: 'Roboto Slab', serif;
			font-weight: 300;
			font-size: 14px;

			span{
				color: $clear-gray;
			}
		}
	}

}

.page-termos {
	padding-bottom: 60px;

	.header-termos{
		margin-top: 54px;

		p{
			margin-top: 3px;
			margin-bottom: 32px;
			color: $clear-gray;
			font-family: Rubik;
			font-weight: 300;
			font-size: 12px;
		}
	}

	.text-normal{
		margin-bottom: 23px;
	}

	.title, .text-slab{
		margin-bottom: 23px;
	}
}