.icon{
	display: inline-block;
}

.icon-google{
	background-image: url('../assets/icons/google.svg');
	width: 18px;
	height: 18px;
}

.icon-facebook{
	background-image: url('../assets/icons/facebook.svg');
	width: 18px;
	height: 18px;
}