@import "../../scss/variables.scss";
.redefinir-senha{
	.note-info{
		position: absolute;
		background-color: $dark-gray;
		border-radius: 5px;
		width: 306px;
		color: white;
		padding: 10px;
		right: -13px;
		font-size: 13px;
		font-family: 'Roboto', sans-serif;
		font-weight: bold;
		font-style: normal;
		z-index: 1;
		top: 31px;
		display: none;

		&:before {
			content: "";
			width: 0;
			height: 0;
			border: 10px solid transparent;
			border-bottom-color: $dark-gray;
			position: absolute;
			right: 15px;
			top: -16px;
		}
	}

	.icon-info{
		position: absolute;
		right: 13px;
		top: 13px;
		color: $clear-gray;
		cursor: pointer;

		&:hover .note-info{
			display: block;
		}
	}

	.forca-senha{
		font-family: 'Roboto', sans-serif;
	    margin-bottom: 10px;
    	display: block;
	}
}	
