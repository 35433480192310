/********************************* COLORS *********************************/
.color-orange{
	color: $orange;
}

.color-white{
	color: $white;
}

.color-bright-gray{
	color: $bright-gray;
}

.color-clear-gray{
	color: $clear-gray;
}

.color-weak-green{
	color: $weak-green;
}

.color-dark-gray{
	color: $dark-gray;
}

.bg-smooth-gray{
	background-color: $smooth-gray;
}

.bg-light-gray{
	background-color: $light-gray;
}

.bg-dark-gray{
	background-color: $dark-gray;
}

.bg-weak-green{
	background-color: $weak-green;
}

.bg-white{
	background-color: $white;
}

.color-red{
	color: #ec3030;
}

/*************************************************************************/

.d-block-desktop{
	@media(min-width: 991px){
		display: block;
	}
}

.w-100-mobile{
	@media(max-width: 992px){
		width: 100%;
	}	
}

.uppercase{
	text-transform: uppercase;
}

.borderless{
	border: 0;
}

.cursor-pointer{
	cursor: pointer;
}

.float-left{
	float: left;
}

.float-right{
	float: right;
}

.overflow-auto{
	overflow: auto;
}

.expired p {
	text-decoration: line-through;
}

.d-none{
	display: none;
}

.p-relative{
	position: relative;
}

.mb-10{
	margin-bottom: 10px;
}

.mt-20{
	margin-top: 20px;
}

.mt-60{
	margin-top: 60px;
}

.mt-10{
	margin-top: 10px;
}

.mb-8{
	margin-bottom: 8px;
}

.mr-5{
	margin-right: 5px;
}

.overflow-hidden{
	overflow: hidden;
}

.text-center{
	text-align: center;
}

.d-inline-block{
	display: inline-block;
}

.d-block{
	display: block;
}

.word-break{
	word-break: break-all;
}